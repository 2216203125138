let initialState = {
  loading: false,
};

export default function appReducer(state = initialState, action) {
  let modules = state.cards;
  switch (action.type) {
    case 'START_LOADING':
      return Object.assign({}, state, {loading: true});
    case 'STOP_LOADING':
      return Object.assign({}, state, {loading: false});
    default:
      return state;
  }
}
