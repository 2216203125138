import React, {useEffect} from 'react';
import {useRouteError} from 'react-router-dom';
import ReactGA from 'react-ga';
import PageWrapper from '../components/PageWrapper';
import Grid from '../components/grid';
import Button from '../components/button';

export default function ErrorPage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div id="error-page">
      <PageWrapper>
        <Grid
          container
          maxWidth="lg"
          rowSpacing={{
            xs: 4,
            md: 12,
          }}
          columnSpacing={4}
          className="error-block">
          <Grid sm={12} md={5}>
            <div className="error-message">
              <h2>OUPS...</h2>
              <br />
              <br />
              <p>
                La page que vous recherchez a peut-être été supprimée, son nom a
                changé ou est temporairement indisponible.
              </p>

              <Button dark text="Retourner à l’accueil" to="/" />
            </div>
          </Grid>
          <Grid sm={12} md={7}>
            <div className="error-image">
              <img src="/images/car-broken.jpeg" alt="block-image" />
            </div>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
