import React, { useEffect, useState } from "react";
import Grid from "../components/grid";
import Button from "../components/button";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { radioUncheck, setUTMValues, isDev } from "../shared/helpers";
import { getPartner } from "../shared/Partner";
import ReCAPTCHA from "react-google-recaptcha";

import { yupResolver } from "@hookform/resolvers/yup";

/* eslint-disable */
yup.setLocale({
  mixed: {
    required: "Ce champ est obligatoire",
  },
});

// fields name
const nameKilometrage = isDev ? "00N7a00000JXRLk" : "00N68000004KdzV";
const nameBudget = isDev ? "00N7a00000KWxCT" : "00N68000004Kp9r";
const nameApport = isDev ? "00N7a00000KWxCn" : "00N68000004Kp9m";
const nameDate = isDev ? "00N7a00000JWOoH" : "00N68000004KdzY";
const nameHoure = isDev ? "00N7a00000KWxCs" : "00N68000004KpAa";
const nameReprise = isDev ? "00N7a00000KWxCx" : "00N68000004KpAf";
const nameMarque = isDev ? "00N7a00000JXRA6" : "00N68000004KdzO";
const nameModele = isDev ? "00N7a00000JXRD5" : "00N68000004KdzZ";
const nameCodePartner = isDev ? "00N7a00000KXVxy" : "00N68000004KpA6";
const nameAandT = "00N7a00000KxuJz";
const nameConseile = isDev ? "00N7a00000KWxHj" : "00N68000004Kp9w";

const schema = yup.object().shape({
  first_name: yup.string().max(40).required(),
  last_name: yup.string().max(80).required(),
  phone: yup.number().typeError("doit être un nombre").required(),
  city: yup.string().max(40).required(),
  email: yup
    .string()
    .email("S'il vous plaît, mettez une adresse email valide")
    .max(80)
    .required(),
  [nameKilometrage]: yup.number().typeError("doit être un nombre").required(), // Kilométrage
  [nameBudget]: yup.number().typeError("doit être un nombre").required(), // Budget
  [nameApport]: yup.number().typeError("doit être un nombre").required(), // Apport
  [nameDate]: yup.string().required(), // Date
  [nameHoure]: yup.string().required(), // Houre
  [nameReprise]: yup.string().nullable(), // Reprise
  [nameMarque]: yup.string().max(100), // Marque
  [nameModele]: yup.string().max(100), // Modele
  [nameCodePartner]: yup.string(), // Code Partner
  [nameAandT]: yup
    .string()
    .oneOf(["1"], "Vous devez accepter les Termes")
    .required("Vous devez accepter les Termes"),
});
/* eslint-enabled*/

const houres = [
  { value: "8h-10h", label: "8h-10h" },
  { value: "10h-12h", label: "10h-12h" },
  { value: "12h-14h", label: "12h-14h" },
  { value: "14h-16h", label: "14h-16h" },
  { value: "16h-18h", label: "16h-18h" },
  { value: "18h-20h", label: "18h-20h" },
];

export default function FormRenew({ spaceMember }: { spaceMember?: boolean }) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [captcha, setCaptcha] = useState({ isNotRobot: false, error: false });
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  function onChange(value: any) {
    if (value) {
      setCaptcha({ isNotRobot: true, error: false });
    }
  }

  const consultation = watch(nameConseile);
  const watchCity = watch("city"); // you can supply default value as second argument
  useEffect(() => {
    if (consultation !== "J’ai déjà trouvé mon véhicule") {
      setValue(nameModele, "");
      setValue(nameMarque, "");
    }
  }, [consultation]);

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
    if (watchCity) {
      setValue("city", watchCity.toUpperCase());
    }
  }, [watch, watchCity]);

  useEffect(() => {
    setRecaptchaToken(process.env.REACT_APP_RECAPTCHA_TOKEN || "");
    const asyncPartner = async () => {
      const partner = await getPartner();
      const leadSourceInput: any = document.getElementsByName("lead_source")[0];
      if (partner && partner.code) {
        const partnerInput: any =
          document.getElementsByName(nameCodePartner)[0];
        partnerInput.value = partner.code;
        leadSourceInput.value = "Apport_affaire";
      } else {
        leadSourceInput.value = "Formulaire_site_web";
      }
    };
    asyncPartner();
    setUTMValues();
  }, []);

  const onSubmit = async (data: any, e: any) => {
    if (!captcha.isNotRobot) {
      setCaptcha({ isNotRobot: false, error: true });
      return;
    }
    e.target.submit();
  };

  const renderRecaptcha = () => {
    return (
      <div className="form-captch">
        <ReCAPTCHA sitekey={recaptchaToken} onChange={onChange} />

        {captcha.error && (
          <p className="error error-captch">
            Le captcha est invalide. Veuillez réessayer.
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="block-prolong">
      <Grid container maxWidth="lg" columnSpacing={8}>
        <Grid xs={12} md={12} mdOffset={0}>
          <h3>
            Contacter un conseiller pour personnaliser votre financement
            <span className="small">ET ÊTRE RECONTACTÉ DANS LES 24H</span>
          </h3>
        </Grid>
        <Grid
          xs={12}
          sx={{
            p: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <form
            autoComplete="off"
            className="form"
            id="form-renouveler"
            action={`https://${
              isDev ? "test" : "webto"
            }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              name="recordType"
              id="recordType"
              value={isDev ? "0127a000002njVP" : "01268000000HkFV"}
            />
            <input
              type="hidden"
              name="oid"
              value={isDev ? "00D7a00000055rv" : "00D68000002836s"}
            />
            <input
              type="hidden"
              name="retURL"
              value={
                spaceMember
                  ? `${
                      isDev
                        ? "https://click-and-fi.lagentx.tech"
                        : "https://click-and-fi.fr"
                    }/espace-partenaire?target=renouveler&statut=succes`
                  : `${
                      isDev
                        ? "https://click-and-fi.lagentx.tech"
                        : "https://click-and-fi.fr"
                    }/renouveler?statut=succes`
              }
            />
            {/* code partner*/}
            <input type="hidden" id={nameCodePartner} name={nameCodePartner} />

            {/* UTM */}
            <input id="utm_source" name="00N68000004KpCM" type="hidden" />
            <input id="utm_medium" name="00N68000004KpCR" type="hidden" />
            <input id="utm_term" name="00N68000004KpCW" type="hidden" />
            <input id="utm_campaign" name="00N68000004KpCb" type="hidden" />
            <input id="utm_content" name="00N68000004KpCg" type="hidden" />

            <input id="lead_source" name="lead_source" type="hidden" />

            <Grid container maxWidth="lg" className="form-content" spacing={0}>
              <Grid
                xs={12}
                md={12}
                mdOffset={0}
                sx={{
                  my: 0,
                }}
              >
                <p className="form-title">Informations personnelles</p>
                <Grid
                  container
                  maxWidth="lg"
                  className="form-controllers"
                  columnSpacing={4}
                  sx={{
                    mx: -2,
                  }}
                >
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor="first_name">
                      Prénom*
                    </label>
                    <input
                      className="form-control"
                      placeholder="Jean"
                      {...register("first_name")}
                    />

                    {errors["first_name"]?.message && (
                      <p className="error">
                        {errors["first_name"] &&
                          (errors["first_name"] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor="last_name">
                      Nom*
                    </label>
                    <input
                      className="form-control"
                      placeholder="Dupont"
                      {...register("last_name")}
                    />
                    {errors["last_name"]?.message && (
                      <p className="error">
                        {errors["last_name"] &&
                          (errors["last_name"] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    {" "}
                    <label className="form-label" htmlFor="phone">
                      {" "}
                      Téléphone*{" "}
                    </label>{" "}
                    <input
                      className="form-control"
                      placeholder="+33"
                      {...register("phone")}
                    />
                    {errors["phone"]?.message && (
                      <p className="error">
                        {errors["phone"] &&
                          (errors["phone"] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor="city">
                      Ville*
                    </label>
                    <input
                      className="form-control"
                      placeholder="Lille"
                      {...register("city")}
                    />

                    {errors["city"]?.message && (
                      <p className="error">
                        {errors["city"] &&
                          (errors["city"] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className="form-label" htmlFor="email">
                      Email*
                    </label>
                    <input
                      className="form-control"
                      placeholder="contact@gmail.com"
                      {...register("email")}
                    />
                    {errors["email"]?.message && (
                      <p className="error">
                        {errors["email"] &&
                          (errors["email"] as { message: string }).message}
                      </p>
                    )}
                  </Grid>

                  <Grid xs={12} md={12}>
                    <p className="form-title">Vos critères</p>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor={nameKilometrage}>
                      Kilométrage annuel*
                    </label>
                    <input
                      className="form-control"
                      placeholder="100 000"
                      {...register(nameKilometrage)}
                    />
                    {errors[nameKilometrage]?.message && (
                      <p className="error">
                        {errors[nameKilometrage] &&
                          (errors[nameKilometrage] as { message: string })
                            .message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor={nameBudget}>
                      Budget mensuel souhaité*
                    </label>
                    <input
                      className="form-control"
                      placeholder="250"
                      {...register(nameBudget)}
                    />
                    {errors[nameBudget]?.message && (
                      <p className="error">
                        {errors[nameBudget] &&
                          (errors[nameBudget] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12} mdOffset={0}>
                    <label className="form-label" htmlFor={nameApport}>
                      Apport souhaité*
                    </label>
                    <input
                      className="form-control"
                      placeholder="1234"
                      {...register(nameApport)}
                    />
                    {errors[nameApport]?.message && (
                      <p className="error">
                        {errors[nameApport] &&
                          (errors[nameApport] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor={nameDate}>
                      Date souhaitée*
                    </label>
                    <Controller
                      control={control}
                      name={nameDate}
                      render={({ field }) => (
                        <DatePicker
                          {...register(nameDate)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="00/00/2023"
                          className="form-control"
                          onChange={(date: any) => field.onChange(date)}
                          selected={field.value}
                          minDate={new Date()}
                        />
                      )}
                    />
                    {errors[nameDate]?.message && (
                      <p className="error">
                        {errors[nameDate] &&
                          (errors[nameDate] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <label className="form-label" htmlFor={nameHoure}>
                      Tranche horaire*
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          {...register(nameHoure)}
                          className="form-control form-control-select"
                          options={houres}
                          onBlur={onBlur}
                          onChange={(data: any) => {
                            onChange(data.value);
                          }}
                          value={value ? { value, label: value } : null}
                          placeholder="00h-00h"
                        />
                      )}
                      name={nameHoure}
                    />
                    {errors[nameHoure]?.message && (
                      <p className="error">
                        {errors[nameHoure] &&
                          (errors[nameHoure] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    md={12}
                    className="form-control-wrapper form-control-wrapper-radio"
                  >
                    <input
                      type="radio"
                      className="form-control"
                      id={nameReprise}
                      value="1"
                      {...register(nameReprise)}
                    />
                    <label htmlFor={nameReprise}>
                      J’ai un véhicule à faire reprendre
                    </label>
                    {errors[nameReprise]?.message && (
                      <p className="error">
                        {errors[nameReprise] &&
                          (errors[nameReprise] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    md={12}
                    className="form-control-wrapper form-control-wrapper-radio"
                  >
                    <input
                      type="radio"
                      className="form-control"
                      id="q_1"
                      value="Je souhaite être conseillé dans le choix de mon véhicule"
                      {...register(nameConseile)}
                    />
                    <label htmlFor="q_1">
                      Je souhaite être conseillé dans le choix de mon véhicule
                    </label>
                  </Grid>

                  <Grid
                    xs={12}
                    md={12}
                    className="form-control-wrapper form-control-wrapper-radio"
                  >
                    <input
                      type="radio"
                      className="form-control"
                      value="J’ai déjà trouvé mon véhicule"
                      id="q_1_found"
                      {...register(nameConseile)}
                    />
                    <label htmlFor="q_1_found">
                      J’ai déjà trouvé mon véhicule
                    </label>
                  </Grid>
                  {consultation === "J’ai déjà trouvé mon véhicule" && (
                    <>
                      <Grid xs={12} md={6}>
                        <label className="form-label" htmlFor={nameMarque}>
                          Marque
                        </label>
                        <input
                          className="form-control"
                          placeholder=""
                          {...register(nameMarque)}
                        />
                        {errors[nameMarque]?.message && (
                          <p className="error">
                            {errors[nameMarque] &&
                              (errors[nameMarque] as { message: string })
                                .message}
                          </p>
                        )}
                      </Grid>
                      <Grid xs={12} md={6}>
                        <label className="form-label" htmlFor={nameModele}>
                          Modèle
                        </label>
                        <input
                          className="form-control"
                          placeholder=""
                          {...register(nameModele)}
                        />
                        {errors[nameModele]?.message && (
                          <p className="error">
                            {errors[nameModele] &&
                              (errors[nameModele] as { message: string })
                                .message}
                          </p>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid
                    xs={12}
                    md={12}
                    className="form-control-wrapper form-control-wrapper-checkbox"
                  >
                    <input
                      type="checkbox"
                      value="1"
                      className="form-control"
                      id={nameAandT}
                      {...register(nameAandT)}
                    />
                    <label htmlFor={nameAandT} className="tandc">
                      Je reconnais que les champs marqués d’un astérisque sont
                      obligatoires. Click & FI traite les données recueillies
                      pour répondre à votre demande de renseignement. Pour en
                      savoir plus sur la gestion de vos données personnelles et
                      pour exercer vos droits, reportez-vous à la politique de
                      confidentialité.
                    </label>
                    {errors[nameAandT]?.message && (
                      <p className="error">
                        {errors[nameAandT] &&
                          (errors[nameAandT] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  {recaptchaToken && renderRecaptcha()}
                  <Grid xs={12} md={12}>
                    <Button text="Envoyer" submit dark />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
