import React, { useEffect, useState } from "react";
import Grid from "../components/grid";
import Button from "../components/button";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { radioUncheck, setUTMValues, isDev } from "../shared/helpers";
import { getPartner } from "../shared/Partner";
import ReCAPTCHA from "react-google-recaptcha";

import { yupResolver } from "@hookform/resolvers/yup";
import Icon from "./Icon";

/* eslint-disable */
yup.setLocale({
  mixed: {
    required: "Ce champ est obligatoire",
  },
});

// fields name
const nameDate = isDev ? "00N7a00000JWOoH" : "00N68000004KdzY";
const nameHoure = isDev ? "00N7a00000KWxCs" : "00N68000004KpAa";
const nameMarque = isDev ? "00N7a00000JXRA6" : "00N68000004KdzO";
const nameModele = isDev ? "00N7a00000JXRD5" : "00N68000004KdzZ";
const nameCodePartner = isDev ? "00N7a00000KXVxy" : "00N68000004KpA6";
const nameConseile = isDev ? "00N7a00000KWxHj" : "00N68000004Kp9w";
const nameAandT = "00N7a00000KxuJz";

const schema = yup.object().shape({
  first_name: yup.string().max(40).required(),
  last_name: yup.string().max(80).required(),
  phone: yup.number().typeError("doit être un nombre").required(),
  email: yup
    .string()
    .email("S'il vous plaît, mettez une adresse email valide")
    .max(80)
    .required(),
  [nameDate]: yup.string().required(), // Date
  [nameHoure]: yup.string().required(), // Houre
  [nameCodePartner]: yup.string(), // Code Partner
  [nameAandT]: yup
    .string()
    .oneOf(["1"], "Vous devez accepter les Termes")
    .required("Vous devez accepter les Termes"),
});
/* eslint-enabled*/

const houres = [
  { value: "8h-10h", label: "8h-10h" },
  { value: "10h-12h", label: "10h-12h" },
  { value: "12h-14h", label: "12h-14h" },
  { value: "14h-16h", label: "14h-16h" },
  { value: "16h-18h", label: "16h-18h" },
  { value: "18h-20h", label: "18h-20h" },
];

export default function FormRenew({ spaceMember }: { spaceMember?: boolean }) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const consultation = watch(nameConseile);
  useEffect(() => {
    if (consultation !== "J’ai déjà trouvé mon véhicule") {
      setValue(nameModele, "");
      setValue(nameMarque, "");
    }
  }, [consultation]);

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
  }, [watch]);

  useEffect(() => {
    const asyncPartner = async () => {
      const partner = await getPartner();
      const leadSourceInput: any = document.getElementsByName("lead_source")[0];
      if (partner && partner.code) {
        const partnerInput: any =
          document.getElementsByName(nameCodePartner)[0];
        partnerInput.value = partner.code;
        leadSourceInput.value = "Apport_affaire";
      } else {
        leadSourceInput.value = "Formulaire_site_web";
      }
    };
    asyncPartner();
    setUTMValues();
  }, []);

  const onSubmit = async (data: any, e: any) => {
    e.target.submit();
  };

  return (
    <div className="">
      <form
        autoComplete="off"
        className="form"
        id="form-renouveler"
        action={`https://${
          isDev ? "test" : "webto"
        }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="recordType"
          id="recordType"
          value={isDev ? "0127a000002njVP" : "01268000000HkFV"}
        />
        <input
          type="hidden"
          name="oid"
          value={isDev ? "00D7a00000055rv" : "00D68000002836s"}
        />
        <input
          type="hidden"
          name="retURL"
          value={`${
            isDev
              ? "https://click-and-fi.lagentx.tech"
              : "https://click-and-fi.fr"
          }/?statut=succes&form=renouveler`}
        />
        {/* code partner*/}
        <input type="hidden" id={nameCodePartner} name={nameCodePartner} />

        {/* UTM */}
        <input id="utm_source" name="00N68000004KpCM" type="hidden" />
        <input id="utm_medium" name="00N68000004KpCR" type="hidden" />
        <input id="utm_term" name="00N68000004KpCW" type="hidden" />
        <input id="utm_campaign" name="00N68000004KpCb" type="hidden" />
        <input id="utm_content" name="00N68000004KpCg" type="hidden" />

        <input id="lead_source" name="lead_source" type="hidden" />

        <Grid container maxWidth="lg" className="form-content" spacing={0}>
          <Grid
            xs={12}
            mdOffset={0}
            sx={{
              my: 0,
            }}
          >
            <Grid
              container
              maxWidth="lg"
              className="form-controllers"
              spacing={0}
            >
              <Grid xs={12} md={5.5} mdOffset={0}>
                <label className="form-label" htmlFor="first_name">
                  Prénom*
                </label>
                <input
                  className="form-control"
                  placeholder="Jean"
                  {...register("first_name")}
                />

                {errors["first_name"]?.message && (
                  <p className="error">
                    {errors["first_name"] &&
                      (errors["first_name"] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={1}>
                <label className="form-label" htmlFor="last_name">
                  Nom*
                </label>
                <input
                  className="form-control"
                  placeholder="Dupont"
                  {...register("last_name")}
                />
                {errors["last_name"]?.message && (
                  <p className="error">
                    {errors["last_name"] &&
                      (errors["last_name"] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={0}>
                {" "}
                <label className="form-label" htmlFor="phone">
                  {" "}
                  Téléphone*{" "}
                </label>{" "}
                <input
                  className="form-control"
                  placeholder="+33"
                  {...register("phone")}
                />
                {errors["phone"]?.message && (
                  <p className="error">
                    {errors["phone"] &&
                      (errors["phone"] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={1}>
                <label className="form-label" htmlFor="email">
                  Email*
                </label>
                <input
                  className="form-control"
                  placeholder="contact@gmail.com"
                  {...register("email")}
                />
                {errors["email"]?.message && (
                  <p className="error">
                    {errors["email"] &&
                      (errors["email"] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={0}>
                <label className="form-label" htmlFor={nameDate}>
                  Date souhaitée*
                </label>
                <Controller
                  control={control}
                  name={nameDate}
                  render={({ field }) => (
                    <DatePicker
                      {...register(nameDate)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="00/00/2023"
                      className="form-control"
                      onChange={(date: any) => field.onChange(date)}
                      selected={field.value}
                      minDate={new Date()}
                    />
                  )}
                />
                {errors[nameDate]?.message && (
                  <p className="error">
                    {errors[nameDate] &&
                      (errors[nameDate] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={1}>
                <label className="form-label" htmlFor={nameHoure}>
                  Tranche horaire*
                </label>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      {...register(nameHoure)}
                      className="form-control form-control-select"
                      options={houres}
                      onBlur={onBlur}
                      onChange={(data: any) => {
                        onChange(data.value);
                      }}
                      value={value ? { value, label: value } : null}
                      placeholder="00h-00h"
                    />
                  )}
                  name={nameHoure}
                />
                {errors[nameHoure]?.message && (
                  <p className="error">
                    {errors[nameHoure] &&
                      (errors[nameHoure] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid
                xs={12}
                md={12}
                className="form-control-wrapper form-control-wrapper-checkbox"
              >
                <input
                  type="checkbox"
                  value="1"
                  className="form-control red-term"
                  id={nameAandT}
                  {...register(nameAandT)}
                />
                <label htmlFor={nameAandT} className="tandc">
                  Je reconnais que les champs marqués d’un astérisque sont
                  obligatoires. Click & FI traite les données recueillies pour
                  répondre à votre demande de renseignement. Pour en savoir plus
                  sur la gestion de vos données personnelles et pour exercer vos
                  droits, reportez-vous à la politique de confidentialité.
                </label>
                {errors[nameAandT]?.message && (
                  <p className="error">
                    {errors[nameAandT] &&
                      (errors[nameAandT] as { message: string }).message}
                  </p>
                )}
              </Grid>
            </Grid>

            {/*  */}
            <button type="submit" className="btn-submit-ren">
              envoyer
              <div className="icon">
                <Icon icon="ArrowRight" size={15} />
              </div>
            </button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
