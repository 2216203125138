import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import { connect } from "react-redux";
import HomePage from "./routes/home";
import GuidPage from "./routes/guid";
import AboutPage from "./routes/about";
import RenewPage from "./routes/renew";
import RenewMasterPage from "./routes/renew-master";
import GeneriqueMasterPage from "./routes/generique-master";
import ProlongMasterPage from "./routes/prolong-master";
import ProlongPage from "./routes/prolong";
import ConnectPage from "./routes/connect";
import EspaceMember from "./routes/memberSpace";
import PrivacyPage from "./routes/privacy";
import LegalPage from "./routes/legal";
import ErrorPage from "./routes/error";
import LoadingOverlay from "./components/Loading";
import ConditionsGeneral from "./routes/c-generale-utilisation";
import ConditionsGeneralVente from "./routes/c-generale-vente";
import PolitiqueDeCookies from "./routes/politique-de-cookies";
import { parseGET } from "./shared/helpers";

function App({
  state: {
    app: { loading },
  },
}: any) {
  type utmParams = {
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_term: string;
    utm_content: string;
  };
  const UTM_PARAMS: utmParams = {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  };
  useEffect(() => {
    UTM_PARAMS.utm_source = parseGET("utm_source");
    UTM_PARAMS.utm_medium = parseGET("utm_medium");
    UTM_PARAMS.utm_campaign = parseGET("utm_campaign");
    UTM_PARAMS.utm_term = parseGET("utm_term");
    UTM_PARAMS.utm_content = parseGET("utm_content");
    sessionStorage.setItem("utm_params", JSON.stringify(UTM_PARAMS));
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/comment-ca-marche",
      element: <GuidPage />,
    },
    {
      path: "/qui-sommes-nous",
      element: <AboutPage />,
    },
    {
      path: "/renouveler",
      element: <RenewPage />,
    },
    {
      path: "/renouveler-master",
      element: <RenewMasterPage />,
    },
    {
      path: "/generique-master",
      element: <GeneriqueMasterPage />,
    },
    {
      path: "/prolonger-master",
      element: <ProlongMasterPage />,
    },
    {
      path: "/prolonger",
      element: <ProlongPage />,
    },
    {
      path: "/connexion",
      element: <ConnectPage />,
    },
    {
      path: "/espace-partenaire",
      element: <EspaceMember />,
    },
    {
      path: "/politique-de-confidentialite",
      element: <PrivacyPage />,
    },
    {
      path: "/mentions-legales",
      element: <LegalPage />,
    },
    {
      path: "/conditions-generales-utilisation",
      element: <ConditionsGeneral />,
    },
    {
      path: "/conditions-generales-de-vente",
      element: <ConditionsGeneralVente />,
    },
    {
      path: "/politique-de-cookies",
      element: <PolitiqueDeCookies />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
      {loading && <LoadingOverlay />}
    </>
  );
}

function mapStateToProps(state: any) {
  return { state };
}
export default connect(mapStateToProps)(App);
