import React, { useEffect } from "react";
import Button from "../components/button";
import Grid from "../components/grid";
import ReactGA from "react-ga";

export default function RenewPage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="page-master">
      {/* maxWidth: 1728*/}
      <Grid container>
        <div className="block-master-1">
          <div className="wrapper">
            <div className="inner">
              <div className="img-wrapper">
                <img
                  className="before"
                  src="/images/block-master-before.png"
                  alt="before"
                />
                <div
                  className="img"
                  style={{
                    backgroundImage: "url('/images/block-master-1-1.png')",
                  }}
                />
                <img
                  className="after"
                  src="/images/block-master-after.png"
                  alt="before"
                />
              </div>
              <div className="content">
                <img
                  className="logo"
                  src="/images/logo-master.png"
                  alt="logo"
                />
                <div className="content-inner">
                  <h3>Passez à la vitesse supérieure avec Click and Fi !</h3>
                  <Button to="/renouveler" text="Découvrir Click and Fi" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-master-text">
          <p className="top">
            Tic, tac, tic, tac ... soyez prêt(e) pour votre prochain projet
            automobile !
          </p>
          <p>
            Votre contrat de location automobile arrive bientôt à échéance et il
            est grand temps de tourner la page. Vous explorez déjà tous les
            sites Internet pour être certain de faire le bon choix et opter pour
            le véhicule qui correspond à vos nouvelles envies ?
          </p>
        </div>
        <div className="block-master-2">
          <div className="wrapper">
            <div
              className="img"
              style={{
                backgroundImage: "url('/images/block-master-2-1.png')",
              }}
            />
            <div className="cards">
              <div className="card">
                <div className="card-inner">
                  <p className="card-title">Un large catalogue de véhicule</p>
                  <p className="card-description">
                    Profitez d’un large choix de véhicule, directement
                    sélectionné par nos experts. Votre prochaine pépite se
                    trouve forcément chez nous.
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <p className="card-title">Disponibilité rapide</p>
                  <p className="card-description">
                    Notre credo c’est aussi celui du “petit garagiste du
                    quartier”. Profitez de la proximité avec nos équipes pour
                    recevoir votre nouveau véhicule dans la concession la plus proche. Vous ne pouvez pas vous déplacer ? Alors c’est elle
                    qui viendra à vous, directement à votre domicile !
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <p className="card-title">
                    Des contrats ajustables et personnalisables
                  </p>
                  <p className="card-description">
                    Modulez votre kilométrage sur la base de la consommation de
                    votre contrat initial, afin de mieux contrôler vos coûts
                    tout en bénéficiant d&rsquo;une disponibilité rapide de
                    votre véhicule au garage. Votre budget est respecté et vous
                    pouvez tout de même vous plaisir dans le choix du véhicule.
                  </p>
                </div>
              </div>
            </div>
            <img
              className="after"
              src="/images/block-master-after2.png"
              alt="after"
            />
          </div>
        </div>
        <div className="block-master-bottom">
          <p>
            Découvrez dès à présent nos solutions pour votre véhicule et faites
            de belles économies
          </p>
          <Button to="/renouveler" text="En savoir plus sur le financement" />
          <img src="/images/logo.png" alt="logo" className="logo" />
        </div>
      </Grid>
    </div>
  );
}
