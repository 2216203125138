import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";

export default function ConditionsGeneral() {
  return (
    <div className="page-legal">
      <PageWrapper>
        <Head>
          <b>CONDITIONS GÉNÉRALES DE VENTE</b>
        </Head>
        <Grid container spacing={4} maxWidth="lg">
          <Grid sm={12} className="text-privacy">
            <div>
              <p>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente
                (CGV) s&rsquo;appliquent &agrave; l&apos;ensemble des services
                et ventes conclus entre SIMPLY-FY, soci&eacute;t&eacute; par
                actions simplifi&eacute;e au capital social de 54.400 &euro;,
                dont le si&egrave;ge social est situ&eacute; au 8F, rue Jeanne
                Barret &ndash; 21000 Dijon, immatricul&eacute;e au Registre du
                Commerce et des Soci&eacute;t&eacute;s de Dijon sous le
                num&eacute;ro 890 392 509 (le &laquo; <strong>Vendeur</strong>{" "}
                &raquo;) &agrave; des clients (le &laquo;{" "}
                <strong>Client</strong> &raquo;), &agrave; l&rsquo;exclusion de
                tous les autres produits ou services propos&eacute;s par
                SIMPLY-FY, qui sont r&eacute;gis par leurs propres conditions.
              </p>
            </div>
            <div>
              <p>
                Elles ont &eacute;t&eacute; port&eacute;es &agrave; la
                connaissance du client pr&eacute;alablement &agrave; la
                conclusion de toute relation contractuelle.
              </p>
            </div>
            <div>
              <p>
                Toute commande emporte acceptation sans r&eacute;serve des
                pr&eacute;sentes CGV.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 1- D&eacute;finition</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Client : tout Client Particulier ainsi que tout autre client.
              </p>
            </div>
            <div>
              <p>
                Client Particulier : tout client qui agit &agrave; des fins qui
                n&apos;entrent pas dans le cadre de son activit&eacute;
                commerciale, industrielle, artisanale ou lib&eacute;rale.
              </p>
            </div>
            <div>
              <p>
                Contrat &agrave; distance : tout contrat conclu entre SIMPLY-FY
                et un Client, &agrave; distance SIMPLY-FY, sans la
                pr&eacute;sence physique simultan&eacute;e de SIMPLY-FY et du
                Client, par le recours exclusif &agrave; une ou plusieurs
                techniques de communication &agrave; distance
                jusqu&apos;&agrave; la conclus ion du contrat.
              </p>
            </div>
            <div>
              <p>V&eacute;hicule : V&eacute;hicules d&apos;occasion.</p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 2 &ndash; Passation de commande et v&eacute;hicule
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Le Vendeur propose de la location de v&eacute;hicules avec
                option d&rsquo;achat (LOA) &agrave; des Clients
                d&eacute;j&agrave; titulaires d&rsquo;une LOA aupr&egrave;s
                d&rsquo;un tiers et souhaitant obtenir une r&eacute;duction de
                leur loyer contre un allongement de la dur&eacute;e de la
                LOA.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Afin de pouvoir obtenir une proposition de la part du Vendeur,
                le Client doit adresser, sans que cette liste ne soit
                limitative, les &eacute;l&eacute;ments suivants au Vendeur
                :&nbsp;
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li>Copie de la carte grise ;</li>
                  <li>Copie du contrat de LOA du Client avec le tiers ;</li>
                  <li>
                    Montant du solde et nombre de mois restants &agrave; courir
                    ;
                  </li>
                  <li>
                    Douze (12) photographies int&eacute;rieures et
                    ext&eacute;rieures du v&eacute;hicule;
                  </li>
                  <li>
                    Derni&egrave;re facture d&rsquo;entretien du
                    v&eacute;hicule;
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>Contr&ocirc;le du kilom&eacute;trage actuel;</li>
                  <li>
                    Le cas &eacute;ch&eacute;ant, v&eacute;rification du
                    contr&ocirc;le technique;
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  &eacute;tant entendu que SIMPLY-FY ne pourra r&eacute;aliser
                  une proposition commercial au Client uniquement s&rsquo;il
                  continue &agrave; &ecirc;tre le locataire du v&eacute;hicule
                  &ndash; aucune modification relative &agrave;
                  l&rsquo;identit&eacute; du Client ne sera
                  r&eacute;alis&eacute;e.
                </p>
              </div>
            </div>
            <div>
              <p>
                Si SIMPLY-FY ne parvient pas &agrave; v&eacute;rifier
                l&rsquo;ensemble de ces &eacute;l&eacute;ments, aucune
                proposition commerciale ne sera transmise au Client.
              </p>
            </div>
            <div>
              <p>
                En cas de dossier complet, SIMPLY-FY proposera au client une
                offre commerciale comprenant une nouvelle LOA avec un autre
                partenaire &eacute;tablissement financier sur une nouvelle
                dur&eacute;e avec un nouveau kilom&eacute;trage ainsi que de
                nouvelles prestations et une nouvelle valeur de rachat
                d&eacute;termin&eacute;e sur la nouvelle dur&eacute;e et le
                nouveau kilom&eacute;trage.
              </p>
            </div>
            <div>
              <p>
                A ce titre, l&rsquo;offre commerciale comprendra la valeur du
                solde de la LOA initiale du Client ainsi que 500 euros de frais
                administratifs comprenant une extension de garantie de douze
                (12) mois souscrite par SIMLY-FY, les frais de la nouvelle carte
                grise contenant l&rsquo;identit&eacute; du nouvel
                &eacute;tablissement financier et du Client.
              </p>
            </div>
            <div>
              <p>
                Le Client conserve &agrave; toutes les &eacute;tapes son
                v&eacute;hicule, y compris une fois que le bon de commande et le
                nouveau contrat de LOA sont sign&eacute;s par le Client.
              </p>
            </div>
            <div>
              <p>
                Aucun acompte suppl&eacute;mentaire n&rsquo;est demand&eacute;
                au Client. Le Client s&rsquo;engage &agrave; payer les nouveaux
                loyers de sa LOA. A ce titre, SIMPLY-FY proc&eacute;dera au
                remboursement du solde de la LOA d&rsquo;origine permettant de
                constater la cession du v&eacute;hicule. Aussi, le Client
                reconna&icirc;t qu&rsquo;il reste pleinement responsable du
                v&eacute;hicule (amendes, sinistres etc) compte tenu qu&rsquo;il
                conserve la garde du v&eacute;hicule &ndash; la
                responsabilit&eacute; de SIMPLY-FY ne pourra pas &ecirc;tre
                recherch&eacute;e pour un v&eacute;hicule plac&eacute; sous la
                garde du Client. D&egrave;s &eacute;mission, la nouvelle carte
                grise du v&eacute;hicule sera adress&eacute;e au Client.
              </p>
            </div>
            <div>
              <p>
                Le V&eacute;hicule est d&eacute;crit sur le Bon de Commande
                adress&eacute; par SIMPLY-FY au Client. Ce V&eacute;hicule a
                &eacute;t&eacute; choisi pr&eacute;alablement par le Client qui
                d&eacute;clare bien le conna&icirc;tre en &eacute;tant
                actuellement le locataire du V&eacute;hicule.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 3 - Acceptation des Conditions
                    G&eacute;n&eacute;rales de Vente (CGV)
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Tout Bon de Commande adress&eacute; par SIMPLY-FY au Client,
                retourn&eacute; sign&eacute; par le Client &agrave; SIMPLY-FY
                emporte de sa part son adh&eacute;sion pleine et enti&egrave;re
                aux pr&eacute;sentes CGV .
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 4 - Prix</em>
                </strong>
              </p>
            </div>
            <div>
              <p>Prix stipul&eacute; au Bon de Command e</p>
            </div>
            <div>
              <p>
                Le prix de la location du V&eacute;hicule tel que figurant sur
                le Bon de Commande de SIMPLY-FY adress&eacute; au Client
                s&apos;entend TTC et comprend, sans que cette liste ne soit
                limitative :
              </p>
            </div>
            <div>
              <p>
                -le prix du V&eacute;hicule d&eacute;crit avec ses
                &eacute;quipements de s&eacute;rie et options
              </p>
            </div>
            <div>
              <p>
                -le co&ucirc;t de la carte grise d&eacute;finitive d&eacute;pend
                de la puissance fiscale du V&eacute;hicule, du niveau
                d&apos;&eacute;mission de C02 du V&eacute;hicule et du lieu de
                r&eacute;sidence du Client. Son montant sera &agrave; acquitter
                par le Client, en sus du Prix et autres frais dus par le Client.
              </p>
            </div>
            <div>
              <p>Frais de formalit&eacute;s</p>
            </div>
            <div>
              <p>
                Les v&eacute;hicules d&apos;occasions ne
                b&eacute;n&eacute;ficient pas du bonus &eacute;cologique. Les
                mesures gouvernementales s&apos;appliquant sur
                l&apos;ann&eacute;e en cours uniquement, SIMPLY-FY n&apos;est
                pas en mesure de savoir &agrave; l&apos;avance si ces
                dispositifs seront reconduits ni les modalit&eacute;s exactes de
                ces dispositifs dans le cadre d&apos;une &eacute;ventuelle
                reconduction. SIMPLY-FY ne pourra &ecirc;tre tenu pour
                responsable de la suppression ou de la modification de ces
                dispositifs, notamment dans le cadre des v&eacute;hicules
                lou&eacute;s sur une ann&eacute;e et livr&eacute;s sur
                l&rsquo;ann&eacute;e suivante .
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 5 - Modalit&eacute;s de paiement</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Lors de l&apos;acceptation du Bon de Commande, le Client
                s&apos;engage &agrave; s&apos;acquitter au profit de SIMPLY-FY,
                des sommes convenues selon les modalit&eacute;s stipul&eacute;es
                au Bon de Commande
              </p>
            </div>
            <div>
              <p>.&nbsp;</p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 6 -Achat &agrave; cr&eacute;dit, location</em>
                </strong>
              </p>
            </div>
            <div>
              <div>
                <p>
                  Chaque fois que le prix du v&eacute;hicule command&eacute; est
                  acquitt&eacute; &agrave; l&rsquo;aide de LOA, mention en est
                  port&eacute;e sur le bon de commande. La vente sera
                  r&eacute;solue de plein droit sans indemnit&eacute; (en cas
                  d&apos;usage priv&eacute;) :
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    si le pr&ecirc;teur n&apos;a pas inform&eacute; le vendeur
                    de l&apos;attribution du cr&eacute;dit dans un d&eacute;lai
                    de 7 jours &acirc; compter de l&apos;acceptation de
                    l&apos;offre
                  </li>
                  <li>
                    si le client emprunteur exerce son droit de
                    r&eacute;tractation dans un d&eacute;lai de 14 jours ,
                    &agrave; compter de l&apos;acceptation de l&apos;offre
                    (r&eacute;duit &agrave; la date de livraison sans pouvoir
                    &ecirc;tre inf&eacute;rieur &agrave; 3 jours si le client a
                    express&eacute;ment demand&eacute; la livraison
                    anticip&eacute;e).
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  En cas de demande de livraison Imm&eacute;diate, le client
                  devra &eacute;crire de sa main sur le bon de commande &laquo;
                  <em>j</em>
                  <em>e deman</em>
                  <em>de &agrave; </em>
                  <em>&ecirc;tre livr&eacute; im</em>
                  <em>m&eacute;d</em>
                  <em>iatement</em> &raquo;. Le d&eacute;lai l&eacute;gal de
                  r&eacute;tractation du contrat de cr&eacute;dit arrive
                  d&egrave;s lors &agrave; &eacute;ch&eacute;ance &agrave; la
                  date de la livraison sans pouvoir &ecirc;tre inf&eacute;rieur
                  &agrave; trois jours, ni sup&eacute;rieur &agrave; 14 jours
                  suivant la signature du client. Celui-ci est tenu par son
                  contrat de vente principal d&egrave;s le 4eme jour suivant sa
                  signature.
                </p>
              </div>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 7- Contrats &agrave; distance et hors
                    &eacute;tablissement
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                En cas de contrat &agrave; distance ou de contrat hors
                &eacute;tablissement, conclu avec un consommateur au sens de
                l&apos;article pr&eacute;liminaire du code de la consommation-
                ou en cas de contrat hors &eacute;tablissement conclu avec un
                professionnel dont le nombre de salari&eacute;s est
                Inf&eacute;rieur ou &eacute;gal 5, d&egrave;s lors que
                l&apos;objet de ce contrat n&apos;entre pas dans le champ de son
                activit&eacute; principale -le client pourra annuler la commande
                , pendant un d&eacute;lai de 14 jours &agrave; compter de la
                r&eacute;ception du v&eacute;hicule en utilisant notamment le
                formulaire ci-joint ou tout autre d&eacute;claration,
                d&eacute;nu&eacute;e d&apos;ambigu&iuml;t&eacute;, exprimant sa
                volont&eacute; de se r&eacute;tracter.
              </p>
            </div>
            <div>
              <p>
                Il est express&eacute;ment pr&eacute;cis&eacute; que,
                conform&eacute;ment &agrave; l&apos;article L121-21-8 du code de
                la consommation, l&apos;immatriculation du v&eacute;hicule par
                le vendeur et les services ex&eacute;cut&eacute;s &agrave; la
                demande du client ne b&eacute;n&eacute;ficient pas du droit de
                r&eacute;tractation.
              </p>
            </div>
            <div>
              <p>
                En cas d&apos;exercice de ce droit de r&eacute;tractation, le
                client devra restituer le v&eacute;hicule &agrave; ses frais
                dans son &eacute;tat d&apos;origine, au vendeur dans les plus
                brefs d&eacute;lais et au plus tard dans les 14 jours .
              </p>
            </div>
            <div>
              <p>
                Le client est inform&eacute; que dans ce cas, il est responsable
                financi&egrave;rement de la d&eacute;pr&eacute;ciation du
                v&eacute;hicule r&eacute;sultant de manipulations autres que
                celles n&eacute;cessaires pour &eacute;tablir la nature, les
                caract&eacute;ristiques et le bon fonctionnement de celui-ci.
              </p>
            </div>
            <div>
              <div>
                <p>
                  En cons&eacute;quence, lors de la restitution par le client,
                  le vendeur remboursera au client les sommes vers&eacute;es
                  diminu&eacute;es :
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    des frais de remise en &eacute;tat n&eacute;cessaires;&nbsp;
                  </li>
                  <li>
                    des Indemnit&eacute;s l&eacute;galement acceptables dont
                    notamment une indemnit&eacute; de 10&euro; du
                    kilom&eacute;trage parcouru au-del&agrave; d&apos;un forfait
                    de 50 kilom&egrave;tres r&eacute;put&eacute;s
                    n&eacute;cessaires &agrave; l&apos;&eacute;tablissement de
                    la nature, des caract&eacute;ristiques et du bon
                    fonctionnement du v&eacute;hicule;&nbsp;
                  </li>
                  <li>
                    et des sommes vers&eacute;es au titre des services
                    ex&eacute;cut&eacute;s par le vendeur notamment des frais
                    d&apos;immatriculation du v&eacute;hicule et taxes
                    associ&eacute;es (ex : malus) Dans le cadre d&apos;un
                    central hors &eacute;tablissement, pendant un d&eacute;lai
                    de 7 jours &agrave; compter de la signature du
                    pr&eacute;sent bon de commande, le vendeur ne pourra
                    recevoir aucun paiement ou acompte de la part du client.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 8 - Livraison</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Aucune livraison ne sera r&eacute;alis&eacute;e par le Vendeur
                &eacute;tant donn&eacute; que le Client conserve la garde du
                v&eacute;hicule sans aucune interruption.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 9 &bull; Modalit&eacute;s de mise &agrave;
                    disposition
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <div>
                <ol>
                  <li>
                    Aucune mise &agrave; disposition ne sera
                    r&eacute;alis&eacute;e &eacute;tant donn&eacute; que le
                    Client conserve la garde du v&eacute;hicule sans aucune
                    interruption.
                  </li>
                </ol>
              </div>
              <div>
                <p>
                  <strong>
                    <em>Article 10 &bull; Droit de r&eacute;tractation</em>
                  </strong>
                </p>
              </div>
              <div>
                <p>
                  Pour les Contrats &agrave; distance ou &agrave; la suite
                  d&apos;un d&eacute;marchage t&eacute;l&eacute;phonique, le
                  client dispose d&apos;un d&eacute;lai de r&eacute;tractation
                  de quatorze (14) jours ouvr&eacute;s &agrave; compter de la
                  conclusion de la relation contractuelle.
                </p>
              </div>
            </div>
            <div>
              <p>
                Le Client pourra exercer son droit de r&eacute;tractation en
                adressant &agrave; SIMPLY-FY un courrier exprimant sans
                ambigu&iuml;t&eacute; sa volont&eacute; de se r&eacute;tracter
                et contenant les &eacute;l&eacute;ments permettant de
                l&apos;identifier (nom, pr&eacute;nom, adresse num&eacute;ro du
                bon de Commande). Il pourra utiliser &agrave; cette fin le
                formulaire de r&eacute;tractation joint aux pr&eacute;sentes
                conditions g&eacute;n&eacute;rales de vente, qu&apos;il
                adressera &agrave; l&apos;adresse suivante: &laquo; SIMPLY-FY
                &ndash; 8F, rue Jeanne Barret &ndash; 21000 Dijon&raquo;. Le
                client devra &ecirc;tre en mesure de prouver qu&apos;il a
                exerc&eacute; son droit de r&eacute;tractation. Le client ne
                pourra exercer son droit de r&eacute;tractation qu&apos;&agrave;
                la condition que l&apos;ensemble des documents, notamment
                l&eacute;gaux, accompagnant le V&eacute;hicule soient
                restitu&eacute;s au plus tard concomitamment &agrave; la remise
                du V&eacute;hicule.
              </p>
            </div>
            <div>
              <p>
                Les frais r&eacute;sultants du retour du V&eacute;hicule sont
                &agrave; la charge du client.
              </p>
            </div>
            <div>
              <p>
                L&apos;exercice du droit de r&eacute;tractation annule le
                contrat de location avec option d&apos;achat. Aucune
                indemnit&eacute; n&apos;est due.
              </p>
            </div>
            <div>
              <p>
                Le Client devra restituer le V&eacute;hicule au point de vente
                livreur ou &agrave; l&apos;agence de SIMPLY-FY sans retard et au
                plus tard dans les quatorze (14) jours ouvr&eacute;s suivant la
                communication de sa d&eacute;cision de se r&eacute;tracter.
              </p>
            </div>
            <div>
              <p>
                D&egrave;s lors que le client aura exerc&eacute; son droit de
                r&eacute;tractation dars le d&eacute;lai imparti et selon les
                conditions ci avant d&eacute;crites, SIMPLY-FY s&apos;engage,
                d&egrave;s que le V&eacute;hicule lui aura &eacute;t&eacute;
                restitu&eacute; ou d&egrave;s que le client aura fourni la
                preuve de l&apos;exp&eacute;dition du V&eacute;hicule, &agrave;
                rembourser le client, des sommes vers&eacute;es, dans un
                d&eacute;lai de 7 jours.
              </p>
            </div>
            <div>
              <p>
                En toute hypoth&egrave;se, les frais de remise en &eacute;tat du
                V&eacute;hicule restitu&eacute;- si celui-ci a &eacute;t&eacute;
                endommag&eacute; et/ ou accident&eacute; -, seront &agrave; la
                charge du Client.
              </p>
            </div>
            <div>
              <p>
                En outre, si le nombre de kilom&egrave;tres parcourus depuis la
                livraison exc&egrave;de 50 km, le client devra indemniser
                SIMPLY-FY, pour chaque kilom&egrave;tre parcouru au-del&agrave;
                dudit seuil de 50 km, &agrave; hauteur de 10 euros TTC par
                kilom&egrave;tre.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 11 -V&eacute;hicules</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Les V&eacute;hicules propos&eacute;s par SIMPLY-FY sont des
                refinancements de V&eacute;hicules d&apos;occasion dont le
                Client est d&eacute;j&agrave; locataire.&nbsp;
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 12 &bull; Garantie l&eacute;gale de
                    conformit&eacute;
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY rappelle toutefois que l&rsquo;Acheteur
                b&eacute;n&eacute;ficie des garanties l&eacute;gales de
                non-conformit&eacute; ainsi qu&rsquo;&agrave; raison des vices
                cach&eacute;s de la chose vendue, en ce compris les
                d&eacute;fauts de conformit&eacute; r&eacute;sultant de
                l&rsquo;emballage des Produits command&eacute;s sur le Site.
              </p>
            </div>
            <div>
              <p>
                Si l&rsquo;Acheteur constate que le Produit qui lui a
                &eacute;t&eacute; livr&eacute; pr&eacute;sente un d&eacute;faut,
                un d&eacute;faut de conformit&eacute; ou est endommag&eacute;,
                il doit en informer SIMPLY-FY aux coordonn&eacute;es
                mentionn&eacute;es aux pr&eacute;sentes, en lui indiquant la
                nature du d&eacute;faut, de la non-conformit&eacute; ou du
                dommage constat&eacute; et en lui envoyant tout justificatif
                utile, notamment sous la forme de photographie(s).
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY organisera avec le transporteur de son choix les
                modalit&eacute;s du retour, dont elle informera l&rsquo;Acheteur
                par tout moyen utile. SIMPLY-FY supportera les frais de ce
                retour.
              </p>
            </div>
            <div>
              <p>
                Les Produits doivent imp&eacute;rativement &ecirc;tre
                retourn&eacute;s &agrave; SIMPLY-FY.
              </p>
            </div>
            <div>
              <p>
                Les retours des Produits ne respectant pas les modalit&eacute;s
                d&eacute;crites ci-dessus ne pourront pas &ecirc;tre pris en
                compte.
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY proc&eacute;dera aux v&eacute;rifications
                n&eacute;cessaires et proposera &agrave; l&rsquo;Acheteur le
                remplacement du Produit dans la mesure du possible. Si le
                remplacement du Produit est impossible, SIMPLY-FY remboursera
                &agrave; l&rsquo;Acheteur l&rsquo;int&eacute;gralit&eacute; du
                prix pay&eacute; pour le Produit ainsi que les frais de
                livraison correspondants, par tout moyen utile, dans les
                meilleurs d&eacute;lais et au plus tard dans les 14 (quatorze)
                jours suivant la date &agrave; laquelle SIMPLY-FY l&rsquo;aura
                inform&eacute; de l&rsquo;impossibilit&eacute; de remplacer le
                Produit.
              </p>
            </div>
            <div>
              <p>
                Les textes l&eacute;gaux relatifs aux garanties l&eacute;gales
                sont reproduits en Annexe 1 des pr&eacute;sentes.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article - 14 &middot;Garantie commerciale</em>
                </strong>
              </p>
            </div>
            <div>
              <p>Tous les V&eacute;hicules sont garantis:</p>
            </div>
            <div>
              <p>
                Par la garantie constructeur, lorsque celle-ci est encore
                valide, c&apos;est-&agrave;-dire dans un d&eacute;lai de deux
                ans ou plus, courant &agrave; compter de la premi&egrave;re
                immatriculation du V&eacute;hicule.
              </p>
            </div>
            <div>
              <p>
                Le contenu, l&apos;&eacute;tendue et les modalit&eacute;s de
                mises en &oelig;uvre de la garantie sont pr&eacute;cis&eacute;es
                soit dans le carnet de garantie du constructeur fourni avec les
                V&eacute;hicules livr&eacute;s par SIMPLY-FY soit sur le site
                internet du constructeur ou dans son r&eacute;seau.
              </p>
            </div>
            <div>
              <p>
                Le Client est inform&eacute; s&apos;il b&eacute;n&eacute;ficie
                en outre des actions de rappel lors des op&eacute;rations de
                s&eacute;curit&eacute; chez le concessionnaire de la marque le
                plus proche de chez lui.
              </p>
            </div>
            <div>
              <p>
                En aucun cas le Client ne pourra pr&eacute;tendre &agrave; un
                recours contre la soci&eacute;t&eacute; SIMPLY-FY en cas de
                mauvais usage ou de non-respect des instructions du constructeur
                dans l&apos;application de la garantie.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 15 - Assurance</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Le client s&apos;engage &agrave; assurer son V&eacute;hicule
                aupr&egrave;s de son assureur &agrave; compter du jour de la
                livraison.
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY se d&eacute;gage de toute responsabilit&eacute; en cas
                de sinistre intervenant apr&egrave;s la livraison.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 16 &middot;R&eacute;serve de
                    propri&eacute;t&eacute;&nbsp;
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Le v&eacute;hicule ne devient la propri&eacute;t&eacute; du
                client qu&apos;apr&egrave;s complet paiement du prix et des
                accessoires. Cette clause de r&eacute;serve de
                propri&eacute;t&eacute; a &eacute;t&eacute; port&eacute;e
                &agrave; la connaissance du client lors de la signature du Bon
                de Commande.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 17&middot; Cas de force majeure</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Les obligations des parties seront suspendues de plein droit et
                sans formalit&eacute;́ et leur responsabilit&eacute;́
                d&eacute;gag&eacute;e en cas de survenance d&rsquo;un cas de
                force majeure entendu comme tout &eacute;v&egrave;nement
                &eacute;chappant au contr&ocirc;le d&rsquo;une partie et qui ne
                pouvait raisonnablement &ecirc;tre pr&eacute;vu lors de la
                conclusion à la date de la commande et dont les effets ne
                peuvent &ecirc;tre &eacute;vit&eacute;s par des mesures
                appropri&eacute;es et ce, conform&eacute;ment à l&rsquo;article
                1218 du code civil.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Sont notamment consid&eacute;r&eacute;s comme des cas de force
                majeure : la guerre, l&rsquo;&eacute;meute, une
                r&eacute;volution, la gr&egrave;ve, une &eacute;pid&eacute;mie
                mondiale, l&rsquo;incendie ou l&rsquo;explosion, les
                catastrophes naturelles et les conditions
                m&eacute;t&eacute;orologiques exceptionnelles. Cette suspension
                ne s&rsquo;applique cependant pas aux obligations de paiement.
              </p>
            </div>
            <div>
              <p>
                La partie touch&eacute;e par ces circonstances le notifiera,
                imm&eacute;diatement, par LRAR ou tout autre coursier de
                renomm&eacute;e internationale, à l&apos;autre partie et elle
                mettra tous les moyens en &oelig;uvre pour reprendre, d&egrave;s
                que possible, l&rsquo;ex&eacute;cution de ses obligations.
                Apr&egrave;s quatre (4) semaines d&apos;interruption
                d&apos;ex&eacute;cution, chaque Partie peut r&eacute;silier de
                plein droit l&rsquo;Offre, sans indemnit&eacute;́, par
                notification &eacute;crite envoy&eacute;e par LRAR ou tout autre
                coursier de renomm&eacute;e internationale, avec effet à la date
                d&apos;envoi de cette notification.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Article 18 - M&eacute;diation</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Il est &eacute;galement rappel&eacute; que tout consommateur a
                le droit de recourir gratuitement &agrave; un m&eacute;diateur
                de la consommation en vue de la r&eacute;solution amiable du
                litige qui l&rsquo;oppose &agrave; un professionnel. A cet
                effet, SIMPLY-FY garantit &agrave; l&rsquo;Acheteur le recours
                effectif &agrave; un dispositif de m&eacute;diation de la
                consommation.
              </p>
            </div>
            <div>
              <p>
                M&eacute;diation des litiges de consommation :
                Conform&eacute;ment aux dispositions du Code de la consommation
                concernant le r&egrave;glement amiable des litiges, SIMPLY-FY
                adh&egrave;re au Service du M&eacute;diateur du conseil national
                des professions de l&rsquo;automobile (CNPA)
              </p>
            </div>
            <div>
              <p>
                En cas de litige, vous pouvez d&eacute;poser votre
                r&eacute;clamation par email : mediateur@mediateur-cnpa.fr ou
                par voie postale en &eacute;crivant &agrave; 43 Bis route de
                Vaugirard &ndash; cs 8001692197 Meudon Cedex
              </p>
            </div>
            <div>
              <p>
                Vous pouvez recourir au service de m&eacute;diation pour les
                litiges de consommation li&eacute;s &agrave; une commande
                effectu&eacute;e sur internet.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Il est enfin rappel&eacute; que la m&eacute;diation n&apos;est
                pas obligatoire mais uniquement propos&eacute;e afin de
                r&eacute;soudre les litiges en &eacute;vitant un recours
                &agrave; la justice. En cas d&rsquo;&eacute;chec de cette
                proc&eacute;dure de m&eacute;diation ou si l&rsquo;Acheteur
                souhaite saisir une juridiction, les r&egrave;gles du code de
                proc&eacute;dure civile s&rsquo;appliqueront.{" "}
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Acheteur peut &eacute;galement entrer en contact avec la
                plateforme de r&eacute;solution des litiges mise en ligne par la
                Commission Europ&eacute;enne &agrave; l&apos;adresse suivante :{" "}
                <a
                  href="http://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  http://ec.europa.eu/consumers/odr/
                </a>
                .
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 19 - DONNEES A CARACTERE PERSONNEL, NEWSLETTER ET
                    LISTE D&rsquo;OPPOSITION AU DEMARCHAGE TELEPHONIQUE
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                <em>Donn&eacute;es &agrave; caract&egrave;re personnel</em>
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY pratique une politique de protection des
                donn&eacute;es personnelles.
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY comprend que la protection des donn&eacute;es et de la
                vie priv&eacute;e soit un enjeu pour le Client. SIMPLY-FY
                s&rsquo;engage, conform&eacute;ment &agrave; la
                r&eacute;glementation RGPD, &agrave; respecter votre vie
                priv&eacute;e et &agrave; prot&eacute;ger vos donn&eacute;es
                &agrave; caract&egrave;re personnel, c&rsquo;est &agrave; dire
                susceptible de vous identifier directement ou indirectement en
                tant que personne.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Dans le cadre de la Commande, SIMPLY-FY a vocation &agrave;
                r&eacute;colter des donn&eacute;es personnelles du Client.
                SIMPLY-FY s&rsquo;engage &agrave; prot&eacute;ger les
                donn&eacute;es &agrave; caract&egrave;re personnel.
              </p>
            </div>
            <div>
              <p>
                Les fichiers comportant des donn&eacute;es &agrave;
                caract&egrave;re personnel n&eacute;cessaires pour la Commande
                sont notamment conserv&eacute;s sur les serveurs. Ce prestataire
                assure &ecirc;tre en conformit&eacute; avec les prescriptions du
                r&egrave;glement g&eacute;n&eacute;ral sur la protection des
                donn&eacute;es (RGPD). SIMPLY-FY ne communique pas et ne fait
                pas commerce des donn&eacute;es personnelles des Clients.
              </p>
            </div>
            <div>
              <p>
                Le Client consent express&eacute;ment &agrave; la collecte et au
                traitement de ses donn&eacute;es &agrave; caract&egrave;re
                personnel n&eacute;cessaires pour effectuer les Commandes.
              </p>
            </div>
            <div>
              <p>
                Les donn&eacute;es personnelles r&eacute;colt&eacute;es par
                SIMPLY-FY ont pour objet de permettre la r&eacute;alisation de
                la Commande. Les diff&eacute;rentes donn&eacute;es &agrave;
                caract&egrave;re personnelles ne seront pas conserv&eacute;es
                plus longtemps que n&eacute;cessaire aux fins pour lesquelles
                elles ont &eacute;t&eacute; r&eacute;colt&eacute;es, y compris
                au regard du respect des obligations l&eacute;gales ou fiscales.
              </p>
            </div>
            <div>
              <p>
                Conform&eacute;ment aux dispositions de la loi n&deg; 78-17 du 6
                janvier 1978, telle que modifi&eacute;e par la loi
                n&deg;2004-801 du 6 ao&ucirc;t 2004 dite &laquo; Informatique et
                Libert&eacute;s &raquo;, et au r&egrave;glement
                g&eacute;n&eacute;ral sur la protection des donn&eacute;es
                (RGPD), sous r&eacute;serve de justifier de votre
                identit&eacute;, tout Acheteur, quelle que soit sa
                nationalit&eacute;, dispose d&rsquo;un droit
                d&rsquo;acc&egrave;s, de modification et de suppression de ses
                donn&eacute;es &agrave; caract&egrave;re personnel. Chaque
                Client est &eacute;galement en droit de solliciter une
                limitation du traitement de ses donn&eacute;es et dispose, par
                ailleurs, d&rsquo;un droit &agrave; la portabilit&eacute; des
                donn&eacute;es ainsi que d&rsquo;un droit d&rsquo;opposition au
                traitement des donn&eacute;es &agrave; caract&egrave;re
                personnel le concernant.&nbsp;
              </p>
            </div>
            <div>
              <p>
                En tout &eacute;tat de cause, tout Acheteur a le droit de faire
                toute r&eacute;clamation aupr&egrave;s de la CNIL.
              </p>
            </div>
            <div>
              <p>
                <em>Newsletter</em>
              </p>
            </div>
            <div>
              <p>
                En cochant la case pr&eacute;vue &agrave; cet effet ou en
                donnant express&eacute;ment son accord &agrave; cette fin, le
                Client accepte que SIMPLY-FY puisse lui faire parvenir, &agrave;
                une fr&eacute;quence et sous une forme d&eacute;termin&eacute;e
                par elle, une newsletter (lettre d&rsquo;information) pouvant
                contenir des informations relatives &agrave; son
                activit&eacute;.
              </p>
            </div>
            <div>
              <p>
                <em>
                  Liste d&rsquo;opposition au d&eacute;marchage
                  t&eacute;l&eacute;phonique
                </em>
              </p>
            </div>
            <div>
              <p>
                Vous avez la possibilit&eacute; de vous inscrire gratuitement
                sur une liste d&rsquo;opposition au d&eacute;marchage
                t&eacute;l&eacute;phonique BLOCTEL (www.bloctel.gouv.fr) afin de
                ne plus &ecirc;tre d&eacute;march&eacute;
                t&eacute;l&eacute;phoniquement par un professionnel avec lequel
                vous n&rsquo;avez pas de relation contractuelle en cours,
                conform&eacute;ment &agrave; la loi n&deg;2014-344 du 17 mars
                2014 relative &agrave; la consommation.
              </p>
            </div>
            <div>
              <p>
                Tout consommateur a la possibilit&eacute; de s&rsquo;inscrire
                gratuitement sur cette liste sur le site
                <a
                  href="https://conso.bloctel.fr/index.php/inscription.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://conso.bloctel.fr/index.php/inscription.php
                </a>
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>
                    Article 20 &bull; Droit applicable et R&egrave;glement des
                    litiges
                  </em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente
                sont r&eacute;gies par le droit fran&ccedil;ais.
              </p>
            </div>
            <div>
              <p>
                Le Service Client est &agrave; la disposition du client pour
                toute r&eacute;clamation et mettra tout en &oelig;uvre pour y
                r&eacute;pondre.
              </p>
            </div>
            <div>
              <p>
                Les coordonn&eacute;es de SIMPLY-FY sont : SIMPLY-FY, Service
                Client, 8F, rue Jeanne Barret &ndash; 21000 Dijon.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Le client et SIMPLY-FY s&apos;engagent &agrave; faire leurs
                meilleurs efforts pour r&eacute;gler &agrave; l&apos;amiable
                tout diff&eacute;rent n&eacute; de la formation,
                l&apos;interpr&eacute;tation et l&apos;ex&eacute;cution du
                contrat.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  &Agrave; d&eacute;faut de r&eacute;solution amiable, tout
                  litige relatif &agrave; l&apos;interpr&eacute;tation et
                  &agrave; l&apos;ex&eacute;cution des CGV sera port&eacute;
                  devant le Tribunal de Commerce de Dijon, , sauf r&egrave;gles
                  de proc&eacute;dure imp&eacute;ratives contraires.
                </strong>
              </p>
            </div>
            <div>
              <p>
                <strong>ANNEXE 1 - TEXTES LEGAUX RELATIFS AUX GARANTIES</strong>
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-4 du Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                Le vendeur est tenu de livrer un bien conforme au contrat et
                r&eacute;pond des d&eacute;fauts de conformit&eacute; existant
                lors de la d&eacute;livrance. Il r&eacute;pond &eacute;galement
                des d&eacute;fauts de conformit&eacute; r&eacute;sultant de
                l&rsquo;emballage, des instructions de montage ou de
                l&rsquo;installation lorsque celle-ci a &eacute;t&eacute; mise
                &agrave; sa charge par le contrat ou a &eacute;t&eacute;
                r&eacute;alis&eacute;e sous sa responsabilit&eacute;.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-5 du Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>Le bien est conforme au contrat :</p>
            </div>
            <div>
              <p>
                S&rsquo;il est propre &agrave; l&rsquo;usage habituellement
                attendu d&rsquo;un bien semblable et, le cas
                &eacute;ch&eacute;ant :&ndash; s&rsquo;il correspond &agrave; la
                description donn&eacute;e par le vendeur et poss&egrave;de les
                qualit&eacute;s que celui-ci a pr&eacute;sent&eacute;es &agrave;
                l&rsquo;acheteur sous forme d&rsquo;&eacute;chantillon ou de
                mod&egrave;le ;&ndash; s&rsquo;il pr&eacute;sente les
                qualit&eacute;s qu&rsquo;un acheteur peut l&eacute;gitimement
                attendre eu &eacute;gard aux d&eacute;clarations publiques
                faites par le vendeur, par le producteur ou par son
                repr&eacute;sentant, notamment dans la publicit&eacute; ou
                l&rsquo;&eacute;tiquetage ;Ou s&rsquo;il pr&eacute;sente les
                caract&eacute;ristiques d&eacute;finies d&rsquo;un commun accord
                par les parties ou est propre &agrave; tout usage sp&eacute;cial
                recherch&eacute; par l&rsquo;acheteur, port&eacute; &agrave; la
                connaissance du vendeur et que ce dernier a accept&eacute;.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-7 Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                Les d&eacute;fauts de conformit&eacute; qui apparaissent dans un
                d&eacute;lai de vingt-quatre mois &agrave; partir de la
                d&eacute;livrance du bien sont pr&eacute;sum&eacute;s exister au
                moment de la d&eacute;livrance, sauf preuve contraire. Pour les
                biens vendus d&rsquo;occasion, ce d&eacute;lai est fix&eacute;
                &agrave; six mois. Le vendeur peut combattre cette
                pr&eacute;somption si celle-ci n&rsquo;est pas compatible avec
                la nature du bien ou le d&eacute;faut de conformit&eacute;
                invoqu&eacute;.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-8 Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;acheteur est en droit d&rsquo;exiger la
                conformit&eacute; du bien au contrat. Il ne peut cependant
                contester la conformit&eacute; en invoquant un d&eacute;faut
                qu&rsquo;il connaissait ou ne pouvait ignorer lorsqu&rsquo;il a
                contract&eacute;. Il en va de m&ecirc;me lorsque le
                d&eacute;faut a son origine dans les mat&eacute;riaux
                qu&rsquo;il a lui-m&ecirc;me fournis.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-9 Code consommation</strong>
              </p>
            </div>
            <div>
              <p>
                En cas de d&eacute;faut de conformit&eacute;, l&rsquo;acheteur
                choisit entre la r&eacute;paration et le remplacement du bien.
                Toutefois, le vendeur peut ne pas proc&eacute;der selon le choix
                de l&rsquo;acheteur si ce choix entra&icirc;ne un co&ucirc;t
                manifestement disproportionn&eacute; au regard de l&rsquo;autre
                modalit&eacute;, compte tenu de la valeur du bien ou de
                l&rsquo;importance du d&eacute;faut. Il est alors tenu de
                proc&eacute;der, sauf impossibilit&eacute;, selon la
                modalit&eacute; non choisie par l&rsquo;acheteur.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-10 Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                Si la r&eacute;paration et le remplacement du bien sont
                impossibles, l&rsquo;acheteur peut rendre le bien et se faire
                restituer le prix ou garder le bien et se faire rendre une
                partie du prix. La m&ecirc;me facult&eacute; lui est ouverte :
              </p>
            </div>
            <div>
              <p>
                1&deg; Si la solution demand&eacute;e, propos&eacute;e ou
                convenue en application de l&rsquo;article L. 217-9 ne peut
                &ecirc;tre mise en &oelig;uvre dans le d&eacute;lai d&rsquo;un
                mois suivant la r&eacute;clamation de l&rsquo;acheteur ;
              </p>
            </div>
            <div>
              <p>
                2&deg; Ou si cette solution ne peut l&rsquo;&ecirc;tre sans
                inconv&eacute;nient majeur pour celui-ci compte tenu de la
                nature du bien et de l&rsquo;usage qu&rsquo;il recherche.La
                r&eacute;solution de la vente ne peut toutefois &ecirc;tre
                prononc&eacute;e si le d&eacute;faut de conformit&eacute; est
                mineur. &raquo;
              </p>
            </div>
            <div>
              <p>
                <strong>Article L.217-11 du Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;application des dispositions des articles L. 217-9 et L.
                217-10 a lieu sans aucun frais pour l&rsquo;acheteur. Ces
                m&ecirc;mes dispositions ne font pas obstacle &agrave;
                l&rsquo;allocation de dommages et int&eacute;r&ecirc;ts.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-12 du Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;action r&eacute;sultant du d&eacute;faut de
                conformit&eacute; se prescrit par deux ans &agrave; compter de
                la d&eacute;livrance du bien.
              </p>
            </div>
            <div>
              <p>
                <strong>Article L. 217-16 du Code de la consommation</strong>
              </p>
            </div>
            <div>
              <p>
                Lorsque l&rsquo;acheteur demande au vendeur, pendant le cours de
                la garantie commerciale qui lui a &eacute;t&eacute; consentie
                lors de l&rsquo;acquisition ou de la r&eacute;paration
                d&rsquo;un bien meuble, une remise en &eacute;tat couverte par
                la garantie, toute p&eacute;riode d&rsquo;immobilisation
                d&rsquo;au moins sept jours vient s&rsquo;ajouter &agrave; la
                dur&eacute;e de la garantie qui restait &agrave; courir. Cette
                p&eacute;riode court &agrave; compter de la demande
                d&rsquo;intervention de l&rsquo;acheteur ou de la mise &agrave;
                disposition pour r&eacute;paration du bien en cause, si cette
                mise &agrave; disposition est post&eacute;rieure &agrave; la
                demande d&rsquo;intervention.
              </p>
            </div>
            <div>
              <p>
                <strong>Article 1641 du Code civil</strong>
              </p>
            </div>
            <div>
              <p>
                Le vendeur est tenu de la garantie &agrave; raison des
                d&eacute;fauts cach&eacute;s de la chose vendue qui la rendent
                impropre &agrave; l&rsquo;usage auquel on la destine, ou qui
                diminuent tellement cet usage, que l&rsquo;acheteur ne
                l&rsquo;aurait pas acquise, ou n&rsquo;en aurait donn&eacute;
                qu&rsquo;un moindre prix, s&rsquo;il les avait connus.
              </p>
            </div>
            <div>
              <p>
                <strong>Article 1643 du Code civil</strong>
              </p>
            </div>
            <div>
              <p>
                Il est tenu des vices cach&eacute;s, quand m&ecirc;me il ne les
                aurait pas connus, &agrave; moins que, dans ce cas, il
                n&rsquo;ait stipul&eacute; qu&rsquo;il ne sera oblig&eacute;
                &agrave; aucune garantie.
              </p>
            </div>
            <div>
              <p>
                <strong>Article 1644 du Code civil</strong>
              </p>
            </div>
            <div>
              <p>
                Dans le cas des articles 1641 et 1643, l&rsquo;acheteur a le
                choix de rendre la chose et de se faire restituer le prix, ou de
                garder la chose et de se faire rendre une partie du prix.
              </p>
            </div>
            <div>
              <p>
                <strong>Article 1648 alin&eacute;a 1er du Code civil</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;action r&eacute;sultant des vices r&eacute;dhibitoires
                doit &ecirc;tre intent&eacute;e par l&rsquo;acqu&eacute;reur
                dans un d&eacute;lai de deux ans &agrave; compter de la
                d&eacute;couverte du vice.
              </p>
            </div>
            <div>
              <p>
                <strong>ANNEXE 2 &ndash; FORMULAIRE DE RETRACTATION</strong>
              </p>
            </div>
            <div>
              <p>
                <strong>FORMULAIRE DE RETRACTATION</strong>
              </p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Service retour</em>
                </strong>
              </p>
            </div>
            <div>
              <p>
                Je vous notifie par la pr&eacute;sente, ma r&eacute;tractation
                du contrat portant sur le service/produit ci-apr&egrave;s
                mentionn&eacute; :
              </p>
            </div>
            <div>
              <p>Command&eacute; le :</p>
            </div>
            <div>
              <p>Num&eacute;ro de commande :&nbsp;</p>
            </div>
            <div>
              <p>Nom du client :&nbsp;</p>
            </div>
            <div>
              <p>Adresse du client :</p>
            </div>
            <div>
              <p>Signature du client :</p>
            </div>
            <div>
              <p>Date :</p>
            </div>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
