import React from "react";
import PageWrapper from "../components/PageWrapper";
import Intro from "../components/intro";
import Cards from "../components/cards";
import TextSides from "../components/textSides";
import CardSides from "../components/cardSides";
import Benifits from "../components/benefits";
import Steps from "../components/steps";
import Partners from "../components/partners";
import { Helmet } from "react-helmet";
import SmallIntro from "./../components/SmallIntro";

export default function GuidPage() {
  return (
    <div className="page-home">
      <Helmet>
        <title>
          Click & Fi - Solutions de location et de financement pour vos projets
          auto
        </title>
        <meta
          name="description"
          content="Trouvez les meilleures solutions de location et de financement pour vos projets auto avec Click & Fi. Nos conseillers experts vous accompagnent tout au long de votre projet pour vous aider à trouver le modèle idéal et à optimiser vos coûts."
        />
        <link rel="canonical" href="https://www.click-and-fi.fr" />
      </Helmet>
      <PageWrapper>
        {/* <Intro /> */}
        <SmallIntro />
        <TextSides />
        <CardSides />
        <Benifits />
        <Steps />
        <Partners />
      </PageWrapper>
    </div>
  );
}
