import React, { useEffect, useState } from 'react';
import Grid from '../components/grid';
import Button from '../components/button';
import { getPartner } from '../shared/Partner';

export default function textSides() {
  const [user, setUser] = useState<null | { color: string }>(null);

  useEffect(() => {
    getPartner().then(partner => {
      if (partner && partner.code) {
        setUser(partner);
      }
    });
  }, []);

  return (
    <div className="card-sides-wrapper">
      <Grid container maxWidth="xlg" className="card-sides">
        <Grid xs={12} md={12}>
          <h2 className='block-title'>Vous avez plusieurs choix : </h2>
        </Grid>
        <Grid className="left-side" xs={12} md={6}>
          <div className="content-wrapper">
            <div className="content-inner">
              <div className="content">
                <h3 className="title">
                  Trouvez le modèle idéal dans notre catalogue
                </h3>
                <br />
                <div className="text-content">
                  <p>
                    <b>1</b> Sélectionner la marque ou le type de véhicule parmi
                    l’ensemble des modèles proposés
                  </p>
                  <p>
                    <b>2</b> Location avec Option d’achat ou Location Longue
                    durée, simulez votre financement pour profiter de l’offre la
                    plus adaptée
                  </p>
                  <p>
                    <b>3</b> Un Conseiller Expert vous recontacte pour affiner
                    et finaliser votre demande
                  </p>
                  <p>
                    <b>4</b> Dès l’accord de votre financement, la concession
                    vous contacte pour voir votre véhicule et finaliser les
                    démarches administratives
                  </p>
                </div>

                <Button
                  mb
                  text="Renouveler"
                  to={
                    user
                      ? '/espace-partenaire?target=renouveler'
                      : '/renouveler'
                  }
                />
              </div>
            </div>
          </div>
          <div className="bottom">
            <Button
              text="Renouvelez"
              to={user ? '/espace-partenaire?target=renouveler' : '/renouveler'}
            />
          </div>
        </Grid>
        <Grid className="right-side" xs={12} md={6}>
          <div className="content-wrapper content-wrapper-promo">
            <div className="content-inner">
              <div className="promo">
                <div className="bg"></div>
                <div className="promo-content">
                  <p>
                    Jusqu’à
                    <span>-30</span>
                    sur vos loyers *
                  </p>
                </div>
              </div>
              <div className="content">
                <h3 className="title">
                  Je prolonge le contrat de location de mon véhicule actuel
                </h3>
                <br />
                <div className="text-content">
                  <p>
                    <b>Simplicité :</b> Vous prenez le temps nécessaire pour
                    bien séléctionner votre nouveau véhicule.
                  </p>
                  <p>
                    <b>Flexibilité :</b> Vous réduisez votre budget grâce à des
                    nouveaux loyers calculés selon votre utilisation
                    kilométrique et la nouvelle durée réactualisée.
                  </p>
                  <p>
                    <b>Liberté :</b> En fin de contrat, vous conservez vos 3
                    options, devenir propriétaire, nous restituer votre véhicule
                    ou souscrire à un nouveau contrat.
                  </p>

                  <Button
                    text="Prolonger"
                    mb
                    to={
                      user
                        ? '/espace-partenaire?target=prolonger'
                        : '/prolonger'
                    }
                    dark
                  />
                  <p className="indecator indecator-mb">
                    (*) la baisse du montant des loyers suppose un allongement
                    de la durée de votre contrat de location en cours et votre
                    nouveau kilométrage défini ensemble.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <Button
              text="Prolongez"
              to={user ? '/espace-partenaire?target=prolonger' : '/prolonger'}
              dark
            />
            <p className="indecator">
              (*) la baisse du montant des loyers suppose un allongement de la
              durée de votre contrat de location en cours et votre nouveau
              kilométrage défini ensemble.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
