import React, { useState } from "react";
import Grid from "../components/grid";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { Partner } from "../shared/APIs";
import { setPartner } from "../shared/Partner";

const schema = yup.object().shape({
  code: yup.string().required(),
});

export default function FormLogin() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const onSubmit = (data: any) => {
    setError(null);
    Partner(data.code)
      .then(({ data }) => {
        setPartner(data);
        navigate("/espace-partenaire");
      })
      .catch((err) => {
        console.log("err:", err)
        if (err.response.status === 404) {
          setError("Partner not found");
        } else {
          setError(`Error occurs`);
        }
        reset();
      });
  };

  return (
    <div className="block-login">
      <Grid container maxWidth="lg" margin={0} columnSpacing={6}>
        <Grid xs={12} md={12}>
          <h3>
            <span className="top">
              Retrouvez-nous sur notre <b>Espace partenaire</b>
            </span>
            <span className="bottom">Connectez-vous</span>
          </h3>
        </Grid>
        <Grid xs={12} md={12}>
          <form onSubmit={handleSubmit(onSubmit)} className="form form-login">
            <Grid container padding={0} className="form-content" spacing={0}>
              <Grid xs={12} md={12}>
                <Grid
                  container
                  maxWidth="lg"
                  className="form-controllers"
                  spacing={0}
                >
                  <Grid xs={12} md={12} mdOffset={0}>
                    <label className="form-label" htmlFor="firstName">
                      RENSEIGNEZ VOTRE CODE PARTENAIRE
                    </label>
                    <input
                      className="form-control"
                      placeholder="123456"
                      {...register("code")}
                    />

                    {errors["login"]?.message && (
                      <p className="error">
                        {errors["login"] &&
                          (errors["login"] as { message: string }).message}
                      </p>
                    )}
                    {error && <p className="error"> {error} </p>}
                  </Grid>
                  <Button text="Se connecter" green submit />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
