import React, { useEffect, useState } from "react";
import Button from "../components/button";
import Icon from "./Icon";
import { Link } from "react-router-dom";
import { getPartner } from "../shared/Partner";

const LeftSide = () => {
  const [user, setUser] = useState<null | { color: string }>(null);

  useEffect(() => {
    getPartner().then((partner) => {
      if (partner && partner.code) {
        setUser(partner);
      }
    });
  }, []);
  return (
    <div className="left-side">
      <div className="renouvellement">
        <h2>Renouvellement</h2>
        <ul>
          <li>
            <div className="pill"></div>
            <span className="bold">C&apos;est quoi ?</span> Renouveler sa LOA ou
            sa LLD permet de continuer à profiter des avantages de ces deux
            modèles... avec un véhicule plus récent
          </li>
          <li>
            <div className="pill"></div>
            <span className="bold"> Pour qui ?</span> Pour tous ceux qui aiment
            la flexibilité de la location et qui souhaitent changer de véhicule
          </li>
          <li>
            <div className="pill"></div>
            <span className="bold">Quels avantages ?</span> Suivi personnalisé,
            Expertise, Disponibilité rapide du véhicule
          </li>
        </ul>
        <Link
          to={user ? "/espace-partenaire?target=renouveler" : "/renouveler"}
          className="left-side-btn"
        >
          en savoir plus
          <div className="icon">
            <Icon icon="ArrowRight" size={15} />
          </div>
        </Link>
      </div>
      <div className="prolongation">
        <h2>prolongation</h2>
        <ul>
          <li>
            <div className="pill"></div>
            <span className="bold">C&apos;est quoi ?</span> Prolonger sa LOA
            permet de garder son véhicule en location tout en adaptant son
            contrat
          </li>
          <li>
            <div className="pill"></div>
            <span className="bold">Pour qui ?</span> Pour tous ceux qui sont
            satisfait de leur véhicule actuel et qui souhaitent faire des
            économies
          </li>
          <li>
            <div className="pill"></div>
            <span className="bold">Quels avantages ?</span> Baisse du loyer,
            Préservation des prestations, adaptation du kilométrage
          </li>
        </ul>
        <Link
          to={user ? "/espace-partenaire?target=prolonger" : "/prolonger"}
          className="left-side-btn"
        >
          en savoir plus
          <div className="icon">
            <Icon icon="ArrowRight" size={15} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default LeftSide;
