import React from "react";
import Grid from "../components/grid";

export default function Benifits() {
  return (
    <Grid container maxWidth="lg" className="benefits">
      <Grid
        sm={12}
        md={12}
        sx={{
          mt: {
            md: 4,
            sm: 2,
          },
          mb: {
            md: 8,
            sm: 2,
          },
        }}
      >
        <h2>
          DECOUVRIR EN PLUS NOS <span>GARANTIES</span>.... Securisez votre
          Patrimoine Automobile
        </h2>
      </Grid>
      <Grid sm={12} md={12} className="cards">
        <div className="card">
          <div className="indecator">
            <span className="indecator-icon"></span>
            <p className="indecator-content">
              Cette garantie couvre ce qui peut arriver dans la vie du
              conducteur et de son véhicule.
            </p>
          </div>
          <p>Garantie Perte Financière</p>
        </div>
        <div className="card">
          <div className="indecator">
            <span className="indecator-icon"></span>
            <p className="indecator-content">
              L&#039;Assurance de l&#039;emprunteur prend en charge le solde du
              financement en cas de Décès ou Perte Totale et Irréversible
              d&#039;Autonomie et des mensualités pendant 18 mois maximum en cas
              d&#039;Incapacité Temporaire Totale de travail.
            </p>
          </div>
          <p>Assurance Emprunteur</p>
        </div>
        <div className="card">
          <div className="indecator">
            <span className="indecator-icon"></span>
            <p className="indecator-content">
              Cette prestation prend en charge des frais de réparation (pièces
              et main-d’œuvre) et d’assistance occasionnés par une panne de
              l’une des pièces couvertes, pendant toute la durée du financement.
            </p>
          </div>
          <p>Extension de Garantie</p>
        </div>
        <div className="card">
          <div className="indecator">
            <span className="indecator-icon"></span>
            <p className="indecator-content">
              Cette garantie couvre l’entretien et l’extension de garantie.
            </p>
          </div>
          <p>Pack Excellence</p>
        </div>
      </Grid>
    </Grid>
  );
}
