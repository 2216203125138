import React from "react";
import Icon from "./Icon";
import { useNavigate } from "react-router-dom";

const Button = ({
  text,
  dark,
  green,
  to,
  outlined,
  onPress,
  submit,
  mb,
}: {
  text: string;
  dark: boolean;
  green: boolean;
  to?: string;
  onPress?: any;
  outlined: boolean;
  submit?: boolean;
  mb?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <button
      type={submit ? "submit" : "button"}
      className={`btn${outlined ? " btn-outlined" : ""}${mb ? " btn-mb" : ""}`}
      style={{
        backgroundColor: dark
          ? "#B9764F"
          : outlined
          ? "transparent"
          : green
          ? "#59706D"
          : "#CE9F69",
        border: `1px solid ${outlined ? "#CE9F69" : "transparent"}`,
        color: outlined ? "#CE9F69" : "white",
      }}
      onClick={() => {
        if (onPress) {
          onPress();
        } else if (to) {
          navigate(to);
        }
      }}
    >
      <div className="text-wrapper">
        <span>{text}</span>
      </div>
      <div
        className="icon-wrapper"
        style={{
          borderColor: outlined ? "#CE9F69" : "inhiret",
        }}
      >
        <Icon icon="ArrowRight" size={15} />
      </div>
    </button>
  );
};

Button.defaultProps = {
  dark: false,
  outlined: false,
  onPress: null,
  green: false,
};

export default Button;
