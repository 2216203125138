import React, {useEffect} from "react";
import Button from "../components/button";
import Grid from "../components/grid";
import ReactGA from 'react-ga';

export default function RenewPage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="page-master">
      {/* maxWidth: 1728*/}
      <Grid container>
        <div className="block-master-1">
          <div className="wrapper">
            <div className="inner">
              <div className="img-wrapper">
                <img
                  className="before"
                  src="/images/block-master-before.png"
                  alt="before"
                />
                <div
                  className="img"
                  style={{
                    backgroundImage: "url('/images/block-master-1-3.png')",
                  }}
                />
                <img
                  className="after"
                  src="/images/block-master-after.png"
                  alt="before"
                />
              </div>
              <div className="content">
                <img
                  className="logo"
                  src="/images/logo-master.png"
                  alt="logo"
                />
                <div className="content-inner">
                  <h3>Passez à la vitesse supérieure avec Click and Fi !</h3>
                  <Button to="/prolonger" text="Découvrir Click and Fi" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-master-text">
          <p className="top">
            Tic, tac, tic, tac ... soyez prêt(e) pour votre prochain projet
            automobile !
          </p>
          <p>
            Vous arrivez à la fin de votre contrat de location et allez devoir
            prendre une décision...Racheter ? Restituer au concessionnaire ?
            Renouveler votre voiture ? Il y a pourtant de fortes chances que
            vous soyez satisfait de votre véhicule actuel ou que vous souhaitiez
            vous donner plus de temps pour choisir une nouvelle voiture..
          </p>
        </div>
        <div className="block-master-2">
          <div className="block-master-title">
            <p>Et si Click&Fi vous proposais une 4ème option ?</p>
            <p className="bold">
              Prolongez votre LOA en cours et donnez vous le temps !
            </p>
          </div>
          <div className="wrapper">
            <div
              className="img"
              style={{
                backgroundImage: "url('/images/block-master-2-3.png')",
              }}
            />
            <div className="cards">
              <div className="card">
                <div className="card-inner">
                  <p className="card-title">Suivi personnalisé</p>
                  <p className="card-description">
                    Nos équipes vous accompagnent personnellement afin de vous
                    conseiller au mieux et vous aider à faire des économies
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <p className="card-title">Parcours digitalisé</p>
                  <p className="card-description">
                    Les démarches de souscription sont simples et rapides, avec
                    un parcours 100% digitalisé.
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <p className="card-title">
                    Des contrats ajustables et personnalisables
                  </p>
                  <p className="card-description">
                    Piochez uniquement ce qui correspond à votre besoin dans
                    notre offre complète :entretien - garantie perte financière
                    - option pneumatiques - assurance tous risques. Profitez
                    également d’une flexibilité à toute épreuve sur la durée et
                    le kilométrage.
                  </p>
                </div>
              </div>
            </div>
            <img
              className="after"
              src="/images/block-master-after2.png"
              alt="after"
            />
          </div>
        </div>
        <div className="block-master-bottom">
          <p>
            Découvrez dès à présent nos solutions pour votre véhicule et faites
            de belles économies
          </p>
          <Button to="/prolonger" text="En savoir plus sur le financement" />
          <img src="/images/logo.png" alt="logo" className="logo" />
        </div>
      </Grid>
    </div>
  );
}
