import React, { useEffect, useState } from "react";
import Grid from "./grid";
import { NavLink, Link } from "react-router-dom";
import Icon from "../components/Icon";
import { removePartner, getPartner } from "../shared/Partner";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showLogout, setShowLogout] = useState(true);
  const [user, setUser] = useState<null | { color: string }>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPartner().then((partner) => {
      if (partner && partner.code) {
        setUser(partner);
      }
    });
  }, []);
  const handleLogout = () => {
    removePartner();
    setUser(null);
    navigate("/connexion");
  };
  return (
    <header>
      <Grid container className="container" maxWidth="lg" spacing={2}>
        <Grid item xs={3} className="logo-wrapper">
          <Link to="/">
            <img className="logo" src="/images/logo.png" alt="Logo" />
          </Link>
        </Grid>
        <Grid
          item
          xs={9}
          className={`${showMenu ? "mobile-show" : ""} nav-list`}
        >
          <ul>
            <button
              className="menu-toggler"
              onClick={() => setShowMenu(!showMenu)}
            >
              <Icon
                icon={showMenu ? "Close" : "Menu"}
                size={showMenu ? 25 : 28}
              />
            </button>
            <li>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/comment-ca-marche">Comment ça marche ?</NavLink>
            </li>
            <li>
              <NavLink to="/qui-sommes-nous">Qui sommes nous ?</NavLink>
            </li>
            {!user && (
              <>
                <li>
                  <NavLink to="/renouveler">Renouveler</NavLink>
                </li>
                <li>
                  <NavLink to="/prolonger">Prolonger</NavLink>
                </li>
              </>
            )}
            <li>
              <NavLink to="/connexion">Espace partenaire</NavLink>
            </li>
            {showLogout && user && (
              <li className="logout">
                <div onClick={handleLogout}>
                  <p>Déconnexion</p>
                  <Icon icon="logout" size={showMenu ? 15 : 18} />
                </div>
              </li>
            )}
          </ul>
        </Grid>
      </Grid>
    </header>
  );
};
export default Header;
